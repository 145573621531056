import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import { changeVendorsToBeOptimisedForAllItems } from "../../redux/actions/basketActions";
import { showModal } from "../../redux/actions/modalActions";
import { toggleTourGuide } from "../../redux/actions/tourActions";
import { updateUserGrocerizeAutoModePreference } from "../../redux/actions/userActions";
import { Icon } from "../icons/Icon";
import LoginPrompt from "../popups/StandardPopup";
import TourGuideModal from "../tourGuide/TourGuideModal";
import UnlockPremiumModal from "../popups/UnlockPremiumModal";

interface Props {
  auth0: any;
  basket?: BasketState;
  user: UserState;
  showModal: any;
  updateUserGrocerizeAutoModePreference: any;
  changeVendorsToBeOptimisedForAllItems: any;
  toggleTourGuide: () => void;
}

interface State {
  isOptimised: boolean;
}
class GrocerizeSwitch extends Component<Props, {}> {
  state = {
    isOptimised: this.props.basket?.basketMetaData.isOptimisedPrice,
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      prevProps.basket?.basketMetaData.isOptimisedPrice !==
      this.props.basket?.basketMetaData.isOptimisedPrice
    ) {
      this.setState({
        isOptimised: this.props.basket?.basketMetaData.isOptimisedPrice,
      });
    }
  };
  clickedToggle = () => {
    let mode = !this.props.user.prefers_grocerize_auto_mode;
    this.props.updateUserGrocerizeAutoModePreference(mode);
    if (mode) {
      this.props.changeVendorsToBeOptimisedForAllItems();
    }
  };

  showPopup = (type: "login" | "subscribe" | "manual_mode") => {
    let modalContent;
      if (type === "subscribe") {
          modalContent = () => <UnlockPremiumModal />;
      } else if (type === "manual_mode") {
      modalContent = () => <LoginPrompt type={"manual_mode"} />;
    } else {
      modalContent = () => <LoginPrompt type={"lists"} />;
    }
    this.props.showModal({ component: modalContent });
  };

  startInteractiveGuide = () => {
    this.props.toggleTourGuide();
  };

  render() {
    return (
      <div className="u-ml--66--desktop">
        <div className="u-hide--mobile " style={{ marginTop: "40px" }}></div>
        <div
          className="u-bold u-main-font--small u-color-charcoal u-hide--mobile"
          style={{ fontSize: "14px" }}
        >
          Select how you would like to shop:
        </div>
        <div className="u-flex u-pt--24 u-align-center u-justify-center--mobile">
          <button
            className={
              "u-br--all  u-bold u-main-font--small" +
              (this.props.user.prefers_grocerize_auto_mode
                ? " u-background--magenta u-color-white"
                : " u-background--darkgrey u-color-charcoal")
            }
            style={{ height: "55px", width: "151px", fontSize: "14px" }}
            onClick={() => this.clickedToggle()}
          >
            AUTO
          </button>
          <button
            className="u-background--white u-br--all u-flex u-align-center"
            style={{
              width: "60px",
              height: "37px",
              marginLeft: "-24px",
              marginRight: "-24px",
              zIndex: 2,
            }}
            onClick={() => this.clickedToggle()}
          >
            <div className="u-w-all u-ph--6">
              <div
                className="u-background--magenta u-br--all"
                style={{
                  width: "25px",
                  height: "25px",
                  zIndex: 2,
                  float: this.props.user.prefers_grocerize_auto_mode
                    ? "left"
                    : "right",
                }}
              ></div>
            </div>
          </button>
          <button
            id="manual-mode"
            className={
              "fourth-tour u-br--all  u-bold u-main-font--small" +
              (this.props.user.prefers_grocerize_auto_mode
                ? " u-background--darkgrey u-color-charcoal"
                : " u-background--magenta u-color-white")
            }
            style={{ height: "55px", width: "151px", fontSize: "14px" }}
            onClick={() => this.clickedToggle()}
          >
            MANUAL
          </button>
          <div className="u-ph--24 u-align-center u-hide--mobile">
            <button id="interactive-guide" onClick={this.startInteractiveGuide}>
              <Icon id="question_mark" fill="transparent" size="xl" />
            </button>
          </div>
        </div>
        {window.innerWidth > 600 && <TourGuideModal />}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
  user: state.user,
});

export default withAuth0(
  connect(mapStateToProps, {
    updateUserGrocerizeAutoModePreference,
    showModal,
    changeVendorsToBeOptimisedForAllItems,
    toggleTourGuide,
  })(GrocerizeSwitch)
);
