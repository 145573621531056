import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import { getUserPriceAlerts } from "../../redux/actions/priceAlertActions";
import GrocerizeLoadingWheel from "../grocerize/GrocerizeLoadingWheel";
import PriceAlertCard from "./PriceAlertCard";

interface Props {
  priceAlerts: PriceAlertsState;
  getUserPriceAlerts: any;
  auth0: any;
  showModal: any;
}

interface State {
  isLoading: Boolean;
  priceAlertsToDisplay: PriceAlert[];
}

class PriceAlertRows extends Component<Props, State> {
  state = {
    isLoading: true,
    priceAlertsToDisplay: [],
  };
  componentDidMount = async () => {
    // get lists
    await this.props.getUserPriceAlerts().then(() => {
      this.setState({ isLoading: false });
    });

    this.showLoadingIndicator();
    window.addEventListener("click", this.showLoadingIndicator);
  };

  componentWillUnmount() {
    window.removeEventListener("click", this.showLoadingIndicator);
  }

  componentDidUpdate = (prevProps: Props) => {
    if (
      prevProps.priceAlerts.priceAlerts !== this.props.priceAlerts.priceAlerts
    ) {
      this.setState({
        priceAlertsToDisplay: this.props.priceAlerts.priceAlerts,
      });
    }
  };

  showLoadingIndicator = () => {
    if (this.state.isLoading) {
      const modalContent = () => (
        <div className="o-modal__container o-modal u-w-all">
          <div className="o-modal__content u-flex u-justify-center u-align-center u-h-all u-w-all">
            <GrocerizeLoadingWheel />
          </div>
        </div>
      );

      this.props.showModal({ component: modalContent });
    }
  };

  render() {
    if (this.state.priceAlertsToDisplay?.length === 0) {
      return (
        <div className="u-flex u-justify-center u-p--48  u-h-all u-background--white">
          You have no saved price alerts
        </div>
      );
    }
    return (
      <div className="u-pv--16--mobile">
        {this.state.priceAlertsToDisplay?.length
          ? this.state.priceAlertsToDisplay.map((alert: PriceAlert) => {
              return <PriceAlertCard priceAlert={alert} key={alert.item.id} />;
            })
          : null}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  priceAlerts: state.priceAlerts,
});

export default withAuth0(
  connect(mapStateToProps, { getUserPriceAlerts, showModal })(PriceAlertRows)
);
