import React, { Component } from "react";

import { withAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import { clearBasket } from "../../redux/actions/basketActions";
import { createList, getLists } from "../../redux/actions/listActions";
import { hideModal, showModal } from "../../redux/actions/modalActions";
import Logo from "../naviagtions/Logo";

import { resetCss } from "../../utils/modal";
import { Icon } from "../icons/Icon";
import StandardPopup from "../popups/StandardPopup";
import BasketFooterActionPopups from "./BasketFooterActionPopups";
import UnlockPremiumModal from "../popups/UnlockPremiumModal";
import LoginPrompt from "../popups/StandardPopup";

interface Props {
  clearBasket?: any;
  hideModal?: any;
  basket: BasketState;
  auth0: any;
  getLists: any;
  showModal: any;
  user: UserState;
}

interface State {
  listSaved: boolean;
  showSaveListOptions: boolean;
  showClearBasketButton: boolean;
  isBasketCleared: boolean;
  listName: string;
}

class BasketFooter extends Component<Props, State> {
  state = {
    listSaved: false,
    showSaveListOptions: false,
    showClearBasketButton: false,
    isBasketCleared: false,
    listName: "Default",
  };

  searchKeyStrokeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ listName: e.currentTarget.value });
  };

  showPopup = (type: "login" | "subscribe") => {
    let modalContent;
    if (type === "subscribe") {
      modalContent = () => <UnlockPremiumModal />;
    } else {
      modalContent = () => <LoginPrompt type={"favourites"} />;
    }
    this.props.showModal({ component: modalContent });
  };

  showSaveBasketAsList = () => {
    if (this.props.basket.basketItems?.length) {
      if (this.props.auth0.isAuthenticated) {
        // Are they premium?
        if (this.props.user.isPremium) {

          this.setState({ showSaveListOptions: !this.state.showSaveListOptions });
        } else {
          // prompt to pay for premium
          this.showPopup("subscribe");
        }
      } else {
        // Ask them to create an account
        this.showPopup("login");
      }
    }
    else {
      if (this.props.auth0.isAuthenticated) {
        // Are they premium?
        if (this.props.user.isPremium) {
          //  TODO : Show UI
          const modalContent = () => <StandardPopup type="lists" />;
          this.props.showModal({ component: modalContent });
        } else {
          // prompt to pay for premium
          this.showPopup("subscribe");
        }
      } else {
        // Ask them to create an account
        this.showPopup("login");
      }
    }
  };

  saveBasketAsList = async () => {
    if (this.props.basket.basketItems?.length) {
      await createList({
        title: this.state.listName,
        is_current_basket: false,
        items: this.props.basket.basketItems,
      })
        .then((data) => {
          // change state to tell user
          this.setState({ listSaved: true, showSaveListOptions: false });
          this.props.getLists();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  goToGrocerizeList = async () => {
//    if (this.props.user.isPremium) {
      window.location.href = "/my/grocerize";
/*    } else {
      if (this.props.basket.basketItems?.length) {
        await createList({
          title: this.state.listName,
          is_current_basket: false,
          items: this.props.basket.basketItems,
        })
          .then((data) => {
            // change state to tell user
            window.location.href = "/my/grocerize";
          })
          .catch((e) => {
            this.props.hideModal();
            console.log(e);
          });
      }
    }*/
  };

  showClearBasketButton = () => {
    this.setState({ showClearBasketButton: !this.state.showClearBasketButton });
  };

  clearBasket = () => {
    resetCss();
    this.props.clearBasket();
    this.setState({ showClearBasketButton: false, isBasketCleared: true });
  };

  hideBasketFooterAfterTimeout = () => {
    setTimeout(() => BasketFooterActionPopups, 1300);
  };
  render() {
    if (this.state.listSaved) {
      setTimeout(
        () => this.setState({ listSaved: false, showSaveListOptions: false }),
        1300
      );
    }

    if (this.state.isBasketCleared) {
      setTimeout(() => this.setState({ isBasketCleared: false }), 1300);
    }

    if (this.state.listSaved) {
      this.hideBasketFooterAfterTimeout();
    }
    return (
      <div className="u-absolute u-bottom--0 u-right--0">
        {this.props.basket.basketItems?.length > 0 && (
          <div
            id="basket-footer"
            className=" u-h-all u-relative u-w-all u-background--grey u-flex u-align-center"
            style={{ height: "66px", maxWidth: "447px", width: "100vw" }} // vw hack to force it to fill enter screen
          >
            <div
              className="u-w-half u-h-all u-align-center u-flex u-justify-between u-main-font--vsmall u-color-charcoal u-align-center"
              style={{ fontSize: "14px" }}
            >
              <button
                className={
                  "u-w-half u-h-all u-align-center u-flex u-justify-center u-semi-bold u-color-charcoal" +
                  (this.props.basket.basketItems?.length ? "" : " u-color-grey")
                }
                onClick={() => {
                  if (this.props.basket.basketItems?.length) {
                    this.setState({ showClearBasketButton: true });
                  }
                }}
              >
                Clear list
              </button>
              <button
                className={
                  "u-w-half u-flex u-justify-center u-semi-bold u-color-charcoal " +
                  (this.props.basket.basketItems?.length ? "" : " u-color-grey")
                }
                onClick={this.showSaveBasketAsList}
              >
                Save list
              </button>
            </div>
            <a
              className="u-w-half u-background--magenta u-flex u-justify-center u-align-center u-br--left--medium u-h-all"
              onClick={this.goToGrocerizeList}
            >
              <Logo type="short" />
              <div
                className="u-cursor--pointer u-main-font--small u-bold u-color-white u-ph--8"
                style={{ fontSize: "14px" }}
              >
                Grocerize List
              </div>
            </a>
          </div>
        )}
        {this.state.showSaveListOptions && !this.state.listSaved ? (
          <BasketFooterActionPopups
            content={{
              displayName: "Save list?",
              subtitle: "Save cart as new list?",
              actionHTML: () => {
                return (
                  <div className="u-flex u-justify-between u-background--grey u-p--12">
                    <input // cant auto focus and animate at the same time easily as window viewport moves
                      className=""
                      placeholder={"List name?"}
                      value={this.state.listName}
                      type="text"
                      onChange={(e) => this.searchKeyStrokeChange(e)}
                    />
                    <button
                      className="u-background--magenta u-main-font--small u-bold u-color-white u-ph--16 u-pv--8 u-br--small"
                      onClick={() => this.saveBasketAsList()}
                    >
                      Save list
                    </button>
                  </div>
                );
              },
              cancelAction: () => this.setState({ showSaveListOptions: false }),
            }}
          />
        ) : !this.state.showSaveListOptions && this.state.listSaved ? (
          <BasketFooterActionPopups
            content={{
              displayName: "",
              subtitle: "",
              actionHTML: () => {
                return (
                  <div className="u-flex u-justify-center u-background--grey u-p--12">
                    <div className="o-animate-tick ">
                      <Icon id="tickAnimated" size="xl" />
                    </div>
                  </div>
                );
              },
              cancelAction: () => this.setState({ showSaveListOptions: false }),
            }}
          />
        ) : null}
        {this.state.showClearBasketButton ? (
          <BasketFooterActionPopups
            content={{
              displayName: "Clear cart?",
              subtitle: "Are you sure you want to clear you cart?",
              actionHTML: () => {
                return (
                  <button
                    className="u-background--magenta u-color-white u-ph--32 u-pv--6 u-semi-bold u-br--medium u-main-font--small"
                    style={{ fontSize: 12 }}
                    onClick={() => this.clearBasket()}
                  >
                    Clear
                  </button>
                );
              },
              cancelAction: () =>
                this.setState({ showClearBasketButton: false }),
            }}
          />
        ) : !this.state.showClearBasketButton && this.state.isBasketCleared ? (
          <BasketFooterActionPopups
            content={{
              displayName: "",
              subtitle: "",
              actionHTML: () => {
                return (
                  <div className="u-flex u-justify-center u-background--grey u-p--12">
                    <div className="o-animate-tick ">
                      <Icon id="tickAnimated" size="xl" />
                    </div>
                  </div>
                );
              },
              cancelAction: () =>
                this.setState({
                  showClearBasketButton: false,
                  isBasketCleared: false,
                }),
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
  user: state.user,
});

export default withAuth0(
  connect(mapStateToProps, {
    clearBasket,
    hideModal,
    getLists,
    showModal,
  })(BasketFooter)
);
