import { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import { getWordyDate } from "../../utils/UIFunctions";
import { Icon } from "../icons/Icon";
import ListOptionsModalMobile from "./listOptions/ListOptionsModalMobile";
import UnlockPremiumModal from "../popups/UnlockPremiumModal";

interface Props {
  listMetaData: List;
  showModal?: any;
  user: UserState;
}

interface State {}

class ListCardMobile extends Component<Props, State> {
  clickedOptions = (list: List | undefined) => {
    if (list) {
      const modalContent = () =>
          this.props.user?.isPremium ? (
              <ListOptionsModalMobile list={list} />
          ) : (
              <UnlockPremiumModal />
          );
      this.props.showModal({ component: modalContent });
    }
  };

  render() {
    const list = this.props.listMetaData;

    return (
      <div className="u-background--white u-ph--12 u-pv--24 u-border--bottom--darkgrey">
        <div className="u-w-all u-flex u-justify-between">
          <div className="u-main-font--small u-bold">
            {list.title}{" "}
            <span className="u-color-grey u-semi-bold u-main-font--vsmall u-ph--12">
              {getWordyDate(list.updatedAt)}
            </span>
          </div>
          <button onClick={() => this.clickedOptions(list)}>
            <Icon id="threedots" />
          </button>
        </div>
        <div className="u-flex u-pt--12">
          <div className="u-main-font--small u-semi-bold u-w-all u-m--2 u-background--grey u-p--6 u-br--small">
            <div
              className="u-flex u-justify-center u-w-all"
              style={{ fontSize: "12px" }}
            >
              Items
            </div>
            <div className="u-flex u-justify-center u-w-all u-bold u-pt--4">
              {list.itemsCount}
            </div>
          </div>
          <div className="u-main-font--small u-semi-bold u-w-all u-m--2 u-background--grey u-p--6 u-br--small">
            <div
              className="u-text-right u-ph--12 u-w-all"
              style={{ fontSize: "12px" }}
            >
              Total
            </div>
            <div className="u-text-right u-ph--12 u-w-all u-bold u-pt--4">
              ${list?.grocerizeShopTotal.toFixed(2)}
            </div>
          </div>
          <div className="u-main-font--small u-bold u-w-all u-m--2 u-background--magenta u-color-white u-p--6 u-br--small">
            <div
              className="u-text-right u-ph--12 u-w-all u-align-center u-flex u-justify-flex-end"
              style={{ fontSize: "12px" }}
            >
              <Icon id="grocerize" size="xs" />
              <span className="u-ph--4"> Savings</span>
            </div>
            <div className="u-text-right u-ph--12 u-w-all u-bold u-pt--4">
              ${list?.grocerizeTotalSavings.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default connect(mapStateToProps, { showModal })(ListCardMobile);
