import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import { updatePriceAlert } from "../../redux/actions/priceAlertActions";
import { Icon } from "../icons/Icon";
import PriceAlertCardMobile from "./PriceAlertCardMobile";
import PriceAlertStatus from "./PriceAlertStatus";
import PriceAlertOptionsModal from "./priceAlertOptions/PriceAlertOptionsModal";

import variables from "../../styles/utils/variables.scss";
import UnlockPremiumModal from "../popups/UnlockPremiumModal";

interface Props {
  priceAlert: PriceAlert;
  updatePriceAlert: any;
  showModal: any;
  user?: UserState;
}

interface State {
  newAlertPrice: number;
}

class PriceAlertCard extends Component<Props, State> {
  state = {
    newAlertPrice: this.props.priceAlert.alert_price,
  };

  searchKeyStrokeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newAlertPrice: parseFloat(e.currentTarget.value) });
  };

  onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const characterCode = e.charCode || e.keyCode || e.key;
    // if user presses enter
    if (characterCode === 13) {
      this.props.updatePriceAlert(
        this.props.priceAlert.item.id,
        this.state.newAlertPrice
      );
    }
  };

  clickedOptions = (priceAlert: PriceAlert | undefined) => {
    if (priceAlert?.item.id === undefined) {
      return;
    }
    const button = document.getElementById(priceAlert.item.id.toString());

    if (button) {
      const postionOfButton = button.getBoundingClientRect();

      const modalContent = this.props.user?.isPremium
          ? () => (
              <div
                  style={{
                    marginTop: postionOfButton.bottom + 20 + "px", // fudge factor as the button size is not working?
                    marginLeft: postionOfButton.left - 40 + "px", // place content left and caret right
                    width: postionOfButton.right - postionOfButton.left + "px",
                  }}
              >
                <PriceAlertOptionsModal priceAlert={priceAlert} />
              </div>
          )
          : () => <UnlockPremiumModal />;
      this.props.showModal({ component: modalContent });
    }
  };

  render() {
    const priceAlert = this.props.priceAlert;
    const isUserPremium = this.props.user?.isPremium;
    const violet = variables.violetColor;

    return (
        <>
          <div
              className={`u-hide--desktop ${!isUserPremium ? "u-opacity--5" : ""}`}
          >
            <PriceAlertCardMobile priceAlert={priceAlert}/>
          </div>
          <div className={`u-hide--mobile u-border--bottom--grey`}>
            <div className="u-ph--48 u-flex u-justify-center">
              <div className="u-w-80pct u-flex u-justify-between">
                <div className="u-flex u-align-center">
                  <div className="u-pl--12--mobile u-flex u-flex-column">
                    <PriceAlertStatus
                        priceAlert={priceAlert}
                        label="Price alert active"
                    />
                    <div className="u-pv--20 u-align-self-center">
                      <img
                          style={{height: "80px"}}
                          alt={priceAlert.item.name}
                          src={priceAlert.item.image_url}
                      />
                    </div>
                  </div>

                  <div className="u-main-font--14--small u-pl--28">
                    {priceAlert.item.name}
                  </div>
                </div>

                <div
                    className="u-flex u-align-center u-main-font--vsmall u-pl--28"
                    style={{fontSize: "14px"}}
                >
                  <div className="u-flex u-justify-center u-align-center">
                    <div
                        className="u-color-grey"
                        style={{marginRight: "-20px"}}
                    >
                      $
                    </div>
                    <input
                        className="u-border--darkgrey u-pv--12 u-text-align-center u-bold"
                        style={{width: "100px"}}
                        value={
                          this.state.newAlertPrice
                              ? Number(this.state.newAlertPrice).toFixed(2)
                              : 0
                        }
                        type="number"
                        step="0.1"
                        onChange={(e) => this.searchKeyStrokeChange(e)}
                        onBlur={(e) => {
                          this.props.updatePriceAlert(
                              priceAlert.item.id,
                              this.state.newAlertPrice
                          );
                        }}
                        onKeyDown={(e) => this.onKeyPress(e)}
                    ></input>
                  </div>
                  <button
                      id={priceAlert.item.id?.toString()}
                      className=" u-pl--40 u-flex u-align-center"
                      onClick={() => this.clickedOptions(priceAlert)}
                  >
                    Options
                    <div className="u-pl--4 u-flex u-align-center">
                      <Icon
                          id="chevron_right"
                          size="m"
                          stroke={violet}
                          fill="none"
                          className="u-rotate--negative90deg"
                      />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default connect(mapStateToProps, {updatePriceAlert, showModal})(
    PriceAlertCard
);
