import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { trackAuthentication } from "../clients/analytics/cliquemarket";
import { captureDataOnSignUp } from "../clients/analytics/mixpanel";
import SignupFormDesktop from "../components/auth/SignupFormDesktop";
import WelcomeDesktop from "../components/auth/WelcomeDesktop";
import GrocerizeLoadingWheel from "../components/grocerize/GrocerizeLoadingWheel";
import { showModal } from "../redux/actions/modalActions";
import { getUserData, saveUserData } from "../redux/actions/userActions";
import PageTemplate from "./PageTemplate";
import {routes} from "../utils/routes";

interface Props {
  saveUserData: any;
  getUserData: any;
  auth0: any;
  user: UserState;
}

interface State {
  attemptedToSubmit: boolean;
  showSignup: boolean;
  showWelcome: boolean;
  isLoading: boolean;
  hasClickedToVerify: boolean;
}

class CompleteSignup extends Component<Props, State> {
  state = {
    attemptedToSubmit: false,
    showSignup: true,
    showWelcome: false,
    isLoading: true,
    hasClickedToVerify: false,
  };

  componentDidMount = async () => {
    await this.props.getUserData();
    await this.checkTypeOfUser();
  };

  checkTypeOfUser = async () => {
    // console.log("this.props.user > ", this.props.user)
    // if there is a first name they have already signed up before, hence this is a login not a signup
    if (this.props.user.email?.length && this.props.user.deletedAt === null) {
      // if they have, they are login in
      this.setState({ showSignup: false, isLoading: false });
    } else {
      // if there is no name, they need to signup
      this.setState({ isLoading: false });
    }
  };

  getWhiteSpaceHeight = () => {
    const headerHeight =
      document.getElementById("header")?.getBoundingClientRect().bottom || 0;
    const footerHeight =
      document.getElementById("footer")?.getBoundingClientRect().height || 215;
    const whiteSpaceHeight = window.innerHeight - (headerHeight + footerHeight);

    return whiteSpaceHeight + "px";
  };

  reloadSignupPage = async () => {
    this.setState({ isLoading: true });

    // need to login the user again as the token needs to be updated
    // This is the simplist way currently (other than writing a new endpoint to check it)
    const { loginWithPopup } = this.props.auth0;
    await loginWithPopup().then(() => {
      // record that the user has clicked the button once as to show a different message to them (which wont be shown once verified)
      this.setState({ hasClickedToVerify: true });
    });

    // toggle loading to show user something is happening after a click (if they haven't verified nothing changes)
    setTimeout(() => this.setState({ isLoading: false }), 1000);
  };

  renderSignupContent = () => {
    // show loading state
    if (this.state.isLoading) {
      return <GrocerizeLoadingWheel />;
    }

    // if user goes directly to /signup without going through auth0
    if (
      this.props.auth0.user === undefined ||
      this.props.auth0.user?.isAuthenticated === false
    ) {
      const redirect_url = window.location.origin.toString() + "/signup";
      this.props.auth0.loginWithRedirect({
        login_hint: "signup",
        redirect_url,
      });
    }

    // ask user to verify their email address if auth0 has loaded and they haven't yet
    // if (
    //   this.props.auth0?.user?.email &&
    //   !this.props.auth0?.user?.email_verified
    // ) {
    //   return (
    //     <VerifyEmailDesktop
    //       hasClickedToVerify={this.state.hasClickedToVerify}
    //       email={this.props.auth0.user.email}
    //       onClickFunc={() => {
    //         this.reloadSignupPage();
    //       }}
    //     />
    //   );
    // }

    // Show signup form
    if (this.state.showSignup) {
      return (
        <SignupFormDesktop
          onComplete={() => {
            this.setState({ showWelcome: true, showSignup: false });
          }}
        />
      );
    }

    if(!process.env.REACT_APP_DEV_MODE) {
      captureDataOnSignUp(this.props.user);
      trackAuthentication(
        {
          id: this.props.user.id,
          email: this.props.user.email,
          prefereredStore: this.props.user.shopping_preference,
          source: this.props.user.auth0_id,
          postcode: this.props.user.postcode,
        },
        "signup"
      );
    }

    // else let them pass to shopping page
    return <Redirect to="/browse" />;
  };

  render() {
    // Show welcome message after they have signuped
    if (this.state.showWelcome) {
      return <Redirect to={routes.premium} />;
    }
    const pageWidth = document.body.scrollWidth;
    return (
      <>
        {pageWidth < 600 ? (
          //Mobile view, redirect to a page
          this.renderSignupContent()
        ) : (
          <>
            <PageTemplate sidebarType="none">
              {/* allow the body to have some height else the footer is weird */}
              <div
                className="u-background--white"
                style={{ height: this.getWhiteSpaceHeight() }}
              ></div>
              {/* have a modal-looking component across the entire content */}
            </PageTemplate>
            <div
              className="u-absolute u-top--0 u-h-all u-w-all o-modal o-modal__background"
              style={{ backdropFilter: " blur(10px)" }}
            >
              <div className="o-modal__container o-modal u-w-all">
                <div className="o-modal__content u-flex u-justify-center u-align-center u-h-all u-w-all">
                  {this.renderSignupContent()}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default withAuth0<any>(
  connect(mapStateToProps, { showModal, saveUserData, getUserData })(
    CompleteSignup
  )
);
