import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import { getLists } from "../../redux/actions/listActions";
import { showModal } from "../../redux/actions/modalActions";
import GrocerizeLoadingWheel from "../grocerize/GrocerizeLoadingWheel";
import ListCard from "./ListCard";

interface Props {
  getLists: any;
  lists: ListsState;
  auth0: any;
  showModal: any;
}

interface State {
  isLoading: boolean;
  listsToDisplay: List[];
}

class ListRows extends Component<Props, State> {
  state = {
    isLoading: true,
    listsToDisplay: [],
  };

  componentDidMount = async () => {
    // get lists
    await this.props.getLists().then(() => {
      this.setState({ isLoading: false });
    });

    this.showLoadingIndicator();
    window.addEventListener("click", this.showLoadingIndicator);
  };

  componentWillUnmount() {
    window.removeEventListener("click", this.showLoadingIndicator);
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.lists.lists !== this.props.lists.lists) {
      this.setState({ listsToDisplay: this.props.lists.lists });
    }
  };

  showLoadingIndicator = () => {
    if (this.state.isLoading) {
      const modalContent = () => (
        <div className="o-modal__container o-modal u-w-all">
          <div className="o-modal__content u-flex u-justify-center u-align-center u-h-all u-w-all">
            <GrocerizeLoadingWheel />
          </div>
        </div>
      );

      this.props.showModal({ component: modalContent });
    }
  };

  render() {
    if (this.state.listsToDisplay.length === 0) {
      return (
        <div className="u-flex u-justify-center u-p--48  u-h-all u-background--white">
          You have no saved lists
        </div>
      );
    }

    return (
      <div className="u-w-all u-h-all">
        {this.state.listsToDisplay.length
          ? this.state.listsToDisplay.map((list, index: number) => {
              return <ListCard listMetaData={list} key={index} />;
            })
          : null}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  lists: state.lists,
});

export default withAuth0(
  connect(mapStateToProps, { getLists, showModal })(ListRows)
);
