import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "../../src/components/icons/Icon";
import { showPaymentPortal } from "../clients/payments/stripe";
import LoginPrompt from "../components/popups/StandardPopup";
import { showModal } from "../redux/actions/modalActions";
import { resetCss } from "../utils/modal";
import PageTemplate from "./PageTemplate";

interface Props {
  user: UserState;
  showModal: any;
}

interface State {
  loadingStripe: boolean;
}

class PremiumPage extends Component<Props, State> {
  state = {
    loadingStripe: false,
  };

  componentDidMount(): void {
    resetCss();
  }

  clickedToUpgrade = () => {
    if (!this.props.user?.id){
      this.props.showModal({
        position: "centered",
        component: () => <LoginPrompt type={"alerts"} />,
      });
    } else if (!this.props.user?.isPremium) {
      showPaymentPortal(this.props.user.stripe_id, "").catch((err) => { console.log(err) });
    }
  };

  returnPriceCard = (price: number) => {
    return (
      <div className="u-flex u-justify-center ">
        <div
          className="u-relative u-br--medium u-border--white--thick"
          style={{ width: 200 }}
        >
          <div className="u-mauto u-pv--12 u-ph--42">
            <div
              className="u-text-left"
              style={{ fontSize: 12, lineHeight: 1 }}
            >
              only
            </div>
            <div>
              <span className="u-bold" style={{ fontSize: 22 }}>
                $
              </span>
              <span
                className="u-bold"
                style={{ fontSize: 40, height: 30, lineHeight: 1 }}
              >
                {price}
              </span>
            </div>
            <div
              className="u-text-right"
              style={{ fontSize: 12, lineHeight: 1 }}
            >
              per month
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderListOfFeatures() {
    const features = [
      "Create and save unlimited lists",
      "Set price alerts on items",
      "Add items to favourites",
      "Maximise your savings by Grocerizing (split) your list",
      "Use 'manual mode' to customise your savings threshold",
      "Best way to save even more money!",
    ];
    return (
      <div className="u-flex u-flex-column u-ph--48 u-w-80pct u-mauto u-w-fit">
        {features.map((feature, index) => {
          return (
            <div
              key={index}
              className={
                "u-flex u-align-center" +
                (index + 1 === features.length ? " u-bold u-color-magenta" : "")
              }
              style={{ fontSize: 14, height: 30, paddingBottom: "8px" }}
            >
              <div className="u-ph--12 u-align-center">
                <Icon id="tick" fill="#70C217" />
              </div>
              <div>{feature}</div>
            </div>
          );
        })}
      </div>
    );
  }

  getWhiteSpaceHeight = () => {
    const headerHeight =
      document.getElementById("header")?.getBoundingClientRect().bottom || 0;
    const searchNavHeight =
      document.getElementById("search-navbar")?.getBoundingClientRect()
        .bottom || 0;
    const footerHeight =
      document.getElementById("footer")?.getBoundingClientRect().height || 215;
    const premiumHeaderHeight =
      document.getElementById("premium")?.getBoundingClientRect().height || 215;
    const whiteSpaceHeight =
      window.innerHeight -
      (headerHeight + premiumHeaderHeight + searchNavHeight + footerHeight);

    const whiteSpaceHeightMobileView =
      window.innerHeight - (headerHeight + searchNavHeight);

    return {
      whiteSpaceHeight: whiteSpaceHeight,
      whiteSpaceHeightMobileView: whiteSpaceHeightMobileView,
    };
  };

  render() {
    const whiteSpaceHeight = this.getWhiteSpaceHeight().whiteSpaceHeight + "px";

    return (
      <div>
        <PageTemplate
          sidebarType="none"
          hideItemSearching={true}
          headerType="grey"
        >
          {/* **** This is for Desktop view **** */}
          <div className=" u-hide--mobile">
            <div
              id="premium"
              className="u-w-all u-background--magenta u-main-font--large u-ph--32 u-color-white"
              style={{ paddingTop: "100px", paddingBottom: "100px" }}
            >
              <div className=" u-wrap ">
                <span className="u-bold">Grocerize Premium</span>
                <span className="u-pt--12">
                  {" "}
                  | Unlock all features to save even more!
                </span>
                <div className="u-main-font--medium u-pt--16">
                  Just $2.95 per month. Cancel any time.
                </div>
                <div className="u-pt--48 ">
                  <button
                    className="u-background--secondary u-br--small  u-color-white u-main-font--medium u-text-center u-pv--12 u-ph--32 u-bold"
                    onClick={this.clickedToUpgrade}
                  >
                    Unlock now!
                  </button>
                </div>
              </div>
            </div>
            <div className="u-pt--48 ">
              {this.renderListOfFeatures()}
            </div>
          </div>

          {/* ***** This is for Mobile View ***** */}
          <div
            className="u-flex u-flex-column u-w-all u-relative u-hide--desktop u-h--var--mobile"
            // style={{ height: whiteSpaceHeightMobile }}
          >
            <div
              className="u-flex u-w-all u-relative u-h--half--var--mobile"
              // style={{ height: (calc(var(--vh, 1vh) * 100)) }}
            >
              <div className="u-background--grocerize u-color-white u-w-all u-text-center">
                {/* Grocerize premium logo */}
                <div
                  style={{
                    paddingTop: 50,
                    paddingBottom: "38px",
                  }}
                >
                  <Icon
                    id="grocerize_premium_white"
                    width={243}
                    height={61}
                    fill="#fff"
                  />
                </div>
                <div
                  style={{ width: 282, lineHeight: 1.2 }}
                  className="u-main-font--medium u-bold u-mauto u-pb--24"
                >
                  Maximise your savings with Grocerize™ Premium.
                </div>
                {/* Price card */}
                {this.returnPriceCard(2.95)}
                <div
                  style={{ width: 200, fontSize: 12 }}
                  className="u-mauto u-pt--24"
                >
                  Cancel anytime, no contract, just loads of savings!
                </div>
                <div
                  style={{ width: 282 }}
                  className="u-bold u-pv--32 u-mauto "
                >
                  Flex your savings muscles!
                </div>
              </div>
            </div>
            <div className="u-background--white u-w-all u-hide--desktop">
              <div
                className="u-flex u-justify-center u-bold u-color-charcoal u-pb--24"
                style={{ paddingTop: 70 }}
              >
                Grocerize™ Premium features!
              </div>
              {this.renderListOfFeatures()}
              <div className="u-ph--48 u-pt--48 u-flex u-justify-center">
                {this.state.loadingStripe ? (
                  <div
                    className="u-br--small u-background--magenta u-color-white u-bold u-flex u-justify-center u-align-center"
                    style={{ width: 400, height: 60 }}
                  >
                    <div className="o-loader"></div>
                  </div>
                ) : (
                  <button
                    className="u-flex u-align-center u-justify-center u-br--small u-background--magenta u-color-white u-bold "
                    style={{ width: 400, height: 60, flexWrap: "wrap" }}
                    onClick={this.clickedToUpgrade}
                  >
                    Unlock and maximise your savings!
                  </button>
                )}
              </div>

              <div
                className="u-pt--16 u-mauto u-color-magenta u-flex u-justify-center"
                style={{ fontSize: 14 }}
              >
                <Link to="">Read more about Premium?</Link>
              </div>
            </div>
          </div>
          {/* End of mobile view */}
        </PageTemplate>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default connect(mapStateToProps, { showModal })(PremiumPage);
