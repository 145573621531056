import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { saveNewCategoryFilterSelection } from "../../redux/actions/categoryAction";
import { hideModal } from "../../redux/actions/modalActions";
import { routes } from "../../utils/routes";
import { Icon } from "../icons/Icon";
import NavBar from "./NavBar";
import SocialMediaButton from "./itemNavs/SocialMediaButton";

interface Props {
  auth0: any;
  hideModal: any;
  user: UserState;
  saveNewCategoryFilterSelection: any;
}

interface State {
  showOtherRoutes: boolean;
  showAccountRoutes: boolean;
}

interface Route {
  display_text?: string; // simple text that the button should have on it
  display_html?: any; // for complex display options such as with styling
  important: boolean; // indicator if need bold text and red arrow
  url?: string; // an optional url that should be nav igated to on click
  action?: any; // an optional function that should be run on click
  isExpanded?: boolean; // optional toggle to show expanding behaviour
  isReturnHome?: boolean;
}

class NavBarMobile extends Component<Props, State> {
  state = {
    showOtherRoutes: false,
    showAccountRoutes: false,
  };

  resetTreeHome = () => {
    //Reset the category filter selection
    this.props.saveNewCategoryFilterSelection(null);
    this.props.hideModal();
  };

  renderButtonContent = (route: Route) => {
    return (
      <div
        className={
          " u-main-font--small u-color-white  u-ph--32 u-pv--16 u-flex u-justify-between " +
          (route.important ? "u-bold " : "")
        }
        style={{ borderBottom: "1px solid #5D5D5D" }}
      >
        {route.display_html ? (
          route.display_html()
        ) : (
          <div>{route.display_text}</div>
        )}
        {route.isExpanded ? (
          <Icon
            id="chevron_down"
            fill="white"
            className={
              "u-rotate--negative360deg " +
              (route.important ? "white" : "u-stroke--grey")
            }
          />
        ) : (
          <Icon
            id="chevron_right"
            fill="none"
            className={
              "u-rotate--negative90deg " +
              (route.important ? "u-stroke--magenta" : "u-stroke--grey")
            }
          />
        )}
      </div>
    );
  };

  renderRouteButton = (route: Route) => {
    const content = this.renderButtonContent(route);

    if (route.url) {
      if (route.isReturnHome) {
        return (
          <Link to={route.url} onClick={this.resetTreeHome}>
            {content}
          </Link>
        );
      } else {
        return (
          <Link to={route.url} onClick={this.props.hideModal}>
            {content}
          </Link>
        );
      }
    } else if (route.action) {
      return <div onClick={route.action}>{content}</div>;
    }
  };

  renderSubRouteButton = (route: Route) => {
    if (route.url) {
      return (
        <Link to={route.url} onClick={this.props.hideModal}>
          <div
            className={
              " u-main-font--small u-color-white  u-ph--32 u-pv--16 u-flex u-justify-between u-pl--48 "
            }
            style={{
              borderBottom: "1px solid #5D5D5D",
              backgroundColor: "#585858",
            }}
          >
            {route.display_html ? (
              route.display_html()
            ) : (
              <div>{route.display_text}</div>
            )}
            <Icon
              id="chevron_right"
              fill="none"
              className={
                "u-rotate--negative90deg " +
                (route.important ? "u-stroke--magenta" : "u-stroke--grey")
              }
            />
          </div>
        </Link>
      );
    } else {
      return <SocialMediaButton />;
    }
  };

  betaVersion = () => {
    const surveyUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLSfmtnZOjVv8RKcTRcNdq08X6_aYCFyDOZepXI9Eq8St0N7TMg/viewform?fbzx=20095328871858120571c";

    window.open(surveyUrl, "_blank", "noopener,noreferrer");
  };

  render() {
    // only show when logged in
    const loggedInRoutes = [
      {
        display_text: "Favourites",
        url: routes.myFavourites,
        important: false,
      },
      {
        display_text: "Lists",
        url: routes.myLists,
        important: false,
      },
      {
        display_text: "Price Alerts",
        url: routes.myAlerts,
        important: false,
      },
    ];

    const myAccountRoutes = [
      {
        display_text: "My Details",
        url: routes.myDetails,
        important: false,
      },
      {
        display_text: "Preferences",
        url: routes.preferences,
        important: false,
      },
    ];

    // only shows this on the more dropdown
    const otherRoutes = [
      // { display_text: "About", url: "routes.premium" },
      // { display_text: "Features", url: "routes.premium" },
      // { display_text: "Latest news", url: "routes.premium" },
      {
        display_text: "Contact us",
        url: routes.contact,
        important: false,
      },
      {
        display_text: "FAQs",
        url: routes.faqs,
        important: false,
      },
      {
        display_text: "Terms of Use",
        url: routes.termsOfUse,
        important: false,
      },
      {
        display_text: "Privacy Policy",
        url: routes.privacyPolicy,
        important: false,
      },
    ];

    return (
      <div className="o-modal__container">
        <NavBar
          mainBackgroundClass=" u-background--magenta"
          secondaryBackgroundClass=" u-background--secondary"
        />
        <div className="u-w-all u-background--secondary">
          {this.renderRouteButton({
            url: "/browse",
            display_text: "Return Home",
            important: true,
            isReturnHome: true,
          })}

          {this.renderRouteButton({
            url: "/browse",
            display_text: "Browse Items",
            important: true,
          })}

          {/* show upgrade button if is on trial or hasn't subscribed  */}
          {this.props.user.daysLeftOnTrial
            ? this.renderRouteButton({
                url: "/premium",
                important: true,
                display_html: () => {
                  return (
                    <div>
                      Premium Trial{" "}
                      <span className="u-color-magenta">
                        {this.props.user.daysLeftOnTrial}d left
                      </span>{" "}
                      - Unlock now
                    </div>
                  );
                },
              })
            : 
            this.props.user.isPremium
            ? null 
            : this.renderRouteButton({
                url: "/premium",
                important: true,
                display_text: "Unlock Premium - $2.95p/m",
              })
          }

          {/* Beta Version */}
          {/* show upgrade button if is on trial or hasn't subscribed  */}
          {
            this.renderRouteButton({
               action: () => {
                 this.betaVersion();
               },
               important: true,
               display_html: () => {
                 return (
                   <div className="u-cursor--pointer u-flex u-align-center">
                     {/* Premium Trial{" "} */}
                     <span className="u-color-magenta">
                       {/* {this.props.user.daysLeftOnTrial}d left */}
                       Grocerize Beta
                     </span>{" "}
                     {/* - Unlock now */}- Tell us what you think
                   </div>
                 );
               },
            })
          }

          {/* Show My Account - My Details and Preferences */}
          {this.state.showAccountRoutes ? (
            <div>
              {this.renderRouteButton({
                display_text: "My Account",
                action: () => {
                  this.setState({
                    showAccountRoutes: !this.state.showAccountRoutes,
                  });
                },
                important: true,
                isExpanded: this.state.showAccountRoutes,
              })}

              {myAccountRoutes.map((route, index) => {
                return this.renderSubRouteButton(route);
              })}
            </div>
          ) : (
            this.renderRouteButton({
              display_text: "My Account",
              action: () => {
                this.setState({
                  showAccountRoutes: !this.state.showAccountRoutes,
                });
              },
              important: true,
              isExpanded: this.state.showAccountRoutes,
            })
          )}

          {/* Logged in routes */}
          {this.props.auth0.isAuthenticated
            ? loggedInRoutes.map((route, index) => {
                return this.renderRouteButton(route);
              })
            : null}

          {/* make other display properly */}
          {/* Show other routes if expanded */}
          {this.state.showOtherRoutes ? (
            <div>
              {this.renderRouteButton({
                display_text: "More",
                action: () => {
                  this.setState({
                    showOtherRoutes: !this.state.showOtherRoutes,
                  });
                },
                important: true,
                isExpanded: this.state.showOtherRoutes,
              })}

              {otherRoutes.map((route, index) => {
                return this.renderSubRouteButton(route);
              })}
            </div>
          ) : (
            this.renderRouteButton({
              display_text: "More",
              action: () => {
                this.setState({
                  showOtherRoutes: !this.state.showOtherRoutes,
                });
              },
              important: true,
              isExpanded: this.state.showOtherRoutes,
            })
          )}
          <div className="u-pv--16 u-pl--16">
            <SocialMediaButton />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default withAuth0<any>(
  connect(mapStateToProps, { hideModal, saveNewCategoryFilterSelection })(
    NavBarMobile
  )
);
