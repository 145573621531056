import React, { Component } from "react";
import { connect } from "react-redux";
import { showPaymentPortal } from "../../clients/payments/stripe";
import { showModal, hideModal } from "../../redux/actions/modalActions";
import LoginPrompt from "./StandardPopup";
import { Icon } from "../icons/Icon";
import { Link } from "react-router-dom";

interface Props {
  user: UserState;
  showModal: any;
  hideModal: any;
}

interface State {
  loadingStripe: boolean;
}

class UnlockPremiumModal extends Component<Props, State> {
  state = {
    loadingStripe: false,
  };
  clickedToUpgrade = () => {
    // show a spinner
    this.setState({ loadingStripe: true });

    if (this.props.user.stripe_id) {
      showPaymentPortal(this.props.user.stripe_id, "").catch((err) => { console.log(err) });
    }
  };

  returnPriceCard = (price: number) => {
    return (
      <div className="u-flex u-justify-center ">
        <div
          className="u-relative u-br--medium u-border--white--thick"
          style={{ width: 200 }}
        >
          <div className="u-mauto u-pv--12 u-ph--42">
            <div
              className="u-text-left"
              style={{ fontSize: 12, lineHeight: 1 }}
            >
              only
            </div>
            <div>
              <span className="u-bold" style={{ fontSize: 22 }}>
                $
              </span>
              <span
                className="u-bold"
                style={{ fontSize: 40, height: 30, lineHeight: 1 }}
              >
                {price}
              </span>
            </div>
            <div
              className="u-text-right"
              style={{ fontSize: 12, lineHeight: 1 }}
            >
              per month
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderListOfFeatures() {
    const features = [
      "Create and save unlimited lists",
      "Set price alerts on items",
      "Add items to favourites",
      "Maximise your savings by Grocerizing (split) your list",
      "Use 'manual mode' to customise your savings threshold",
      "Best way to save even more money!",
    ];
    return (
      <div className="u-ph--48 u-w-all u-mauto">
        {features.map((feature, index) => {
          return (
            <div
              key={index}
              className={
                "u-flex u-align-center" +
                (index + 1 === features.length ? " u-bold u-color-magenta" : "")
              }
              style={{ fontSize: 14, height: 30 }}
            >
              <div className="u-ph--12 u-align-center">
                <Icon id="tick" fill="#70C217" />
              </div>
              <div>{feature}</div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    // if not loggedin we need them to sign up
    if (!this.props.user.stripe_id.length) {
      return <LoginPrompt type="cart" />;
    }

    if (window.innerWidth < 600) {
      return (
        <div className="o-modal__container ">
          <div className="u-w-all u-br--medium u-color-white u-text-center o-modal--content-bottom u-background--grocerize">
            <div
              className="u-flex u-justify-center"
              style={{ paddingTop: "70px", paddingBottom: "38px" }}
            >
              <Icon id="grocerize_premium_white" width={243} height={61} />
            </div>
            <div
              style={{ width: 282, lineHeight: 1.2 }}
              className="u-main-font--medium u-bold u-mauto u-pb--24"
            >
              Maximise your savings with Grocerize™ Premium.
            </div>
            {/* Price card */}
            {this.returnPriceCard(2.95)}
            <div style={{ fontSize: 12 }} className="u-mauto u-pv--24">
              Cancel anytime, no contract just loads of savings!
            </div>
            <div className="u-flex u-justify-center">
              {this.state.loadingStripe ? (
                <div className=" u-pv--20 u-ph--20  u-br--small u-background--white u-color-magenta u-flex u-justify-center">
                  <div className="o-loader"></div>
                </div>
              ) : (
                <button
                  className=" u-pv--20 u-ph--20  u-br--small u-background--white u-color-magenta u-bold"
                  onClick={this.clickedToUpgrade}
                >
                  Unlock and maximise your savings!
                </button>
              )}
            </div>
            <div className="u-pv--20">Flex your saving muscles!</div>
          </div>
        </div>
      );
    }
    return (
      <div className="u-br--small o-modal__container ">
        <div
          className=" o-modal--content-centered"
          style={{
            width: "950px",
            height: "500px",
          }}
        >
          <button
            className="u-absolute"
            style={{ top: 18, right: 18, zIndex: 2 }}
            onClick={() => this.props.hideModal()}
          >
            <Icon id="clear" stroke="grey" size="s" />
          </button>

          <div className="u-flex u-w-all u-h-all u-relative">
            <div
              className="u-background--grocerize u-color-white u-w-all u-text-center"
              style={{ width: "45%" }}
            >
              {/* Grocerize premium logo */}
              <div style={{ paddingTop: "70px", paddingBottom: "38px" }}>
                <Icon id="grocerize_premium_white" width={243} height={61} />
              </div>
              <div
                style={{ width: 282, lineHeight: 1.2 }}
                className="u-main-font--medium u-bold u-mauto u-pb--24"
              >
                Maximise your savings with Grocerize™ Premium.
              </div>
              {/* Price card */}
              {this.returnPriceCard(2.95)}
              <div
                style={{ width: 164, fontSize: 12 }}
                className="u-mauto u-pt--24"
              >
                Cancel anytime, no contract, just loads of savings!
              </div>
              <div style={{ width: 282 }} className="u-bold u-pt--32 u-mauto">
                Flex your savings muscles!
              </div>
            </div>
            <div
              className="u-background--white u-w-all"
              style={{ width: "55%" }}
            >
              <div
                className="u-flex u-justify-center u-bold u-color-charcoal u-pb--24"
                style={{ paddingTop: 72 }}
              >
                Grocerize™ Premium features!
              </div>
              {this.renderListOfFeatures()}
              <div className="u-ph--48 u-pt--48">
                {this.state.loadingStripe ? (
                  <div
                    className="u-br--small u-background--magenta u-color-white u-bold u-flex u-justify-center u-align-center"
                    style={{ width: 400, height: 60 }}
                  >
                    <div className="o-loader"></div>
                  </div>
                ) : (
                  <button
                    className="u-br--small u-background--magenta u-color-white u-bold "
                    style={{ width: 400, height: 60 }}
                    onClick={this.clickedToUpgrade}
                  >
                    Unlock and maximise your savings!
                  </button>
                )}
              </div>

              <div
                className="u-pt--20 u-mauto u-color-magenta u-flex u-justify-center"
                style={{ fontSize: 14 }}
              >
                <Link to="">Read more about Premium?</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default connect(mapStateToProps, { showModal, hideModal })(
  UnlockPremiumModal
);
