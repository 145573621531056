import { Component } from "react";
import { connect } from "react-redux";
import { trackItemCart } from "../../../../clients/analytics/cliquemarket";
import { track } from "../../../../clients/analytics/mixpanel";
import {
  addItemToBasket,
  changeVendorsToBeOptimisedForAllItems,
  decreaseBasketItemQuantity,
  increaseBasketItemQuantity,
} from "../../../../redux/actions/basketActions";
import { showModal } from "../../../../redux/actions/modalActions";
import { saveToLocalStorage } from "../../../../utils/localStorageFunctions";
import LoginPrompt from "../../../popups/StandardPopup";

interface Props {
  itemData: ItemData;
  addItemToBasket?: any;
  quantityOfItemInBasket: number;
  increaseBasketItemQuantity?: any;
  decreaseBasketItemQuantity?: any;
  showModal: any;
  user: UserState;
  changeVendorsToBeOptimisedForAllItems: any;
  forDisplay?: boolean;
  basket: BasketState;
}

interface State {
  addToCartIsDisabled: boolean;
}

class AddToBasketButton extends Component<Props, State> {
  state = {
    addToCartIsDisabled: false,
  };

  componentDidMount = () => {
    if (this.props.itemData.item_pricing.length === 1) {
      if (
        (!this.props.itemData.item_pricing[0].available &&
          this.props.itemData.item_pricing[0].price) ||
        this.props?.forDisplay
      ) {
        this.setState({ addToCartIsDisabled: true });
      } else {
        this.setState({ addToCartIsDisabled: false });
      }
    } else {
      if (this.props?.forDisplay) {
        this.setState({ addToCartIsDisabled: true });
      } else {
        this.setState({ addToCartIsDisabled: false });
      }
    }
  };
  checkPricing = () => {
    if (this.props.itemData.item_pricing.length === 1) {
      if (
        !this.props.itemData.item_pricing[0].available &&
        this.props.itemData.item_pricing[0].price
      ) {
        this.setState({ addToCartIsDisabled: true });
      } else {
        this.setState({ addToCartIsDisabled: false });
      }
    }
  };
  addToBasket = async () => {
    // must be signed in to add to cart
    if (!process.env.REACT_APP_DEV_MODE) {
      this.trackItem();
    }

    this.setState({ addToCartIsDisabled: true });
    // add to redux
    await this.props.addItemToBasket(this.props.itemData);

    // saveToLocalStorage({
    //   ...this.props.basket,
    // });

    // change state to display quantity increase
    this.setState({ addToCartIsDisabled: false });
  };

  increaseQuantity = async () => {
    await this.props.increaseBasketItemQuantity(this.props.itemData);

    saveToLocalStorage({
      ...this.props.basket,
    });
  };

  decreaseQuantity = async () => {
    await this.props.decreaseBasketItemQuantity(this.props.itemData);

    saveToLocalStorage({
      ...this.props.basket,
    });
  };

  trackItem = () => {
    this.sendToMixpanel();
    this.sendToCliqueMarket();
  };

  sendToMixpanel = () => {
    track("item--added-to-basket", {
      crawler_id: this.props.itemData.crawler_id,
    });
  };

  sendToCliqueMarket = () => {
    trackItemCart("add-to-cart", this.props.itemData);
  };

  render() {
    if (this.props.quantityOfItemInBasket) {
      return (
        <div className="u-w-all u-flex u-justify-center u-ph--16--desktop u-min-height--48 u-max-height--48">
          <div className="u-flex u-br--vsmall  u-border--darkgrey u-w-all u-pv--8--mobile u-pv--11--desktop">
            <button
              className="u-h-all u-w-all u-flex u-justify-center u-align-center "
              onClick={this.decreaseQuantity}
            >
              -
            </button>
            <div
              className="u-h-all u-w-all u-flex u-justify-center u-align-center  u-bold"
              style={{
                fontSize: "18px",
              }}
            >
              {this.props.itemData.quantity || 0}
            </div>
            <button
              className="u-h-all u-w-all u-flex u-justify-center u-align-center"
              onClick={this.increaseQuantity}
            >
              +
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="u-w-all u-flex u-justify-center u-ph--16--desktop u-min-height--48 u-max-height--48">
        <button
          className={
            "u-br--small " +
            (this.state.addToCartIsDisabled &&
            (!this.props?.forDisplay || this.props?.forDisplay === undefined)
              ? "u-background--darkgrey"
              : "u-background--magenta") +
            " u-w-all"
          }
          onClick={() => this.addToBasket()}
          disabled={this.state.addToCartIsDisabled}
        >
          <div className="u-color-white u-p--12 u-main-font--small u-bold u-text-unselectable ">
            Add to Cart
          </div>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  basket: state.basket,
});

export default connect(mapStateToProps, {
  addItemToBasket,
  increaseBasketItemQuantity,
  decreaseBasketItemQuantity,
  changeVendorsToBeOptimisedForAllItems,
  showModal,
})(AddToBasketButton);
