import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import { updatePriceAlert } from "../../redux/actions/priceAlertActions";
import PriceAlertStatus from "./PriceAlertStatus";
import DeletePriceAlertModalContentMobile from "./priceAlertOptions/DeletePriceAlertModalContentMobile";
import UnlockPremiumModal from "../popups/UnlockPremiumModal";

interface Props {
  priceAlert: PriceAlert;
  updatePriceAlert: any;
  showModal: any;
  user?: UserState;
}

interface State {
  newAlertPrice: number;
}

class PriceAlertCardMobile extends Component<Props, State> {
  state = {
    newAlertPrice: this.props.priceAlert.alert_price,
  };

  showDeletePriceAlert = () => {
    const itemCard = document.getElementById(
      this.props.priceAlert.item.id.toString()
    );
    if (itemCard) {
      const modalContent = () => (
          <div className="u-overflow-hidden">
            {this.props.user?.isPremium && (
                <DeletePriceAlertModalContentMobile
                    priceAlert={this.props.priceAlert}
                />
            )}

            {!this.props.user?.isPremium && <UnlockPremiumModal />}
          </div>
      );
      this.props.showModal({
        position: "bottom",
        component: modalContent,
      });
    }
  };

  searchKeyStrokeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newAlertPrice: parseFloat(e.currentTarget.value) });
  };

  onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const characterCode = e.charCode || e.keyCode || e.key;
    // if user presses enter
    if (characterCode === 13) {
      this.props.updatePriceAlert(
        this.props.priceAlert.item.id,
        this.state.newAlertPrice
      );
    }
  };

  render() {
    const priceAlert = this.props.priceAlert;

    return (
      <div className="u-background--white u-ph--12 u-border--bottom--darkgrey">
        <div>
          <div className="u-w-all u-flex u-align-center">
            <div className="u-w-25pct u-pl--12--mobile">
              <PriceAlertStatus priceAlert={priceAlert} label="Active" />
              <img
                style={{ height: "80px" }}
                alt={priceAlert.item.name}
                src={priceAlert.item.image_url}
              />
            </div>
            <div
              className="u-pl--32 u-color-charcoal u-semi-bold"
              style={{ fontSize: 14 }}
            >
              <div>{priceAlert.item.name}</div>
            </div>
          </div>
          <div className="u-w-all u-flex u-align-center u-pv--12">
            <div className="u-flex u-text-center u-ph--12" style={{ flex: 1 }}>
              <button
                className="u-color-magenta u-w-all u-semi-bold "
                style={{ fontSize: 14 }}
                onClick={this.showDeletePriceAlert}
              >
                Delete
              </button>
            </div>
            <div className="u-pl--20 u-w-all" style={{ flex: 4 }}>
              <div className="u-w-all u-flex u-justify-between u-align-center u-background--grey u-ph--16 u-pv--12">
                <div className="u-pr--12 u-semi-bold" style={{ fontSize: 14 }}>
                  When price at
                </div>
                <div className="u-background--white u-ph--4  u-bold u-flex  u-align-center">
                  <div className=" u-pv--16 u-color-darkgrey">$</div>
                  <input
                    className="u-pv--16 u-bold u-ph--8"
                    style={{ width: "60px" }}
                    value={
                      typeof this.state.newAlertPrice === "string"
                        ? parseFloat(this.state.newAlertPrice).toFixed(2)
                        : this.state.newAlertPrice.toFixed(2)
                    }
                    type="number"
                    step="0.1"
                    onChange={(e) => this.searchKeyStrokeChange(e)}
                    onBlur={(e) => {
                      this.props.updatePriceAlert(
                        priceAlert.item.id,
                        this.state.newAlertPrice
                      );
                    }}
                    onKeyDown={(e) => this.onKeyPress(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapToStateProps = (state: ReduxState) => ({
  user: state.user,
});
export default connect(mapToStateProps, { updatePriceAlert, showModal })(
  PriceAlertCardMobile
);
