import { Component } from "react";
import { connect } from "react-redux";
import { deleteUserFavourite } from "../../redux/actions/favouritesActions";
import { showModal } from "../../redux/actions/modalActions";
import variables from "../../styles/utils/variables.scss";
import { Icon } from "../icons/Icon";
import FavouriteCardMobile from "./FavouriteCardMobile";
import FavouriteOptionsModal from "./favouritesOptions/FavouriteOptionsModal";
import UnlockPremiumModal from "../popups/UnlockPremiumModal";

interface Props {
  itemData: ItemData;
  deleteUserFavourite: any;
  showModal: any;
  user?: UserState;
}

interface State {}

class FavouriteCard extends Component<Props, State> {
  clickedOptions = (itemData: ItemData | undefined) => {
    if (itemData?.id === undefined) {
      return;
    }

    const button = document.getElementById(itemData.id.toString());

    if (button) {
      const positionOfButton = button.getBoundingClientRect();

      const modalContent = this.props.user?.isPremium
          ? () => (
              <div
                  style={{
                    marginTop: positionOfButton.bottom + 20 + "px", // fudge factor as the button size is not working?
                    marginLeft: positionOfButton.left - 40 + "px",
                    width: positionOfButton.right - positionOfButton.left + "px",
                  }}
              >
                <FavouriteOptionsModal itemData={itemData} />
              </div>
          )
          : () => <UnlockPremiumModal />;

      this.props.showModal({ component: modalContent });
    }
  };

  render() {
    const violet = variables.violetColor;

    const itemData = this.props.itemData;
    const isUserPremium = this.props.user?.isPremium;

    return (
      <div>
        <div className={`u-hide--desktop`}>
          <FavouriteCardMobile itemData={itemData} />
        </div>
        <div
            className={`u-hide--mobile u-ph--48 u-pv--12 u-border--bottom--grey u-flex u-justify-center ${
                !isUserPremium ? "u-opacity--5" : ""
            }`}
            style={{height: "121px"}}
        >
          <div className="u-pl--64 u-pl--12--mobile ">
            <img
                style={{height: "80px"}}
                alt={itemData.name}
                src={itemData.image_url}
            />
          </div>
          <div
              className="u-w-80pct u-flex u-align-center u-main-font--vsmall u-pl--28"
              style={{fontSize: "14px"}}
          >
            <div className="u-w-half u-flex u-align-center">
              <div>{itemData.name}</div>
            </div>
            <button
                id={itemData.id.toString()}
                className=" u-pl--40 u-flex u-align-center"
                onClick={() => this.clickedOptions(itemData)}
            >
              Options
              <div className="u-pl--4 u-flex u-align-center">
                <Icon
                    id="chevron_right"
                    size="m"
                    stroke={violet}
                    fill="none"
                    className="u-rotate--negative90deg"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default connect(mapStateToProps, {deleteUserFavourite, showModal})(
    FavouriteCard
);
